@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
  @apply bg-white dark:bg-stone-900;
}

/* Radix UI Tabs */
button[data-state="active"] {
  @apply border-b-2 border-b-blue-500 !text-stone-800 dark:!text-stone-200
}

* {
  @apply focus-visible:outline-1 focus-visible:outline-blue-500 focus-visible:outline-offset-2
}
